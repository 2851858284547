import { Device, useDevice } from '@/custom/useDevice';
import styled, { css } from 'styled-components';
import { useCallback } from 'react';
import { ClassicExperience } from '@/models/ClassicExperience';
import { useRouter } from 'next/router';
import { Client } from '@/models/Client';
import useWebShare from 'react-use-web-share';
import Theme from '@/styles/Theme';
import { useTranslation } from 'next-i18next';
import { gtmEventSocial } from '@/utils/gtm';
import { LightButton } from './button/LightButton';
import { IconShare } from '../icon/IconShare';
import { ShareDesktopButton } from './button/ShareDesktopButton';
import { Logo } from '../common/Logo';
import { ClassicExperienceCard } from './experience/ClassicExperienceCard';
import { useClient } from '../context/ClientContext';

interface Props {
	client: Client;
	classicExperiences: ClassicExperience[];
}

const StyledHome = styled.div<{ device: Device }>`
	flex: 1;

	${(props) => props.device === Device.MOBILE && css`
		margin: 0 ${20 / props.theme.fontSizeBase}rem;
	`};
`;

const StyledHeader = styled.div<{ device: Device }>`
	margin: ${(props) => 8 / props.theme.fontSizeBase}rem 0 ${(props) => 27 / props.theme.fontSizeBase}rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${(props) => props.device === Device.DESKTOP && css`
		margin: ${40 / props.theme.fontSizeBase}rem auto ${27 / props.theme.fontSizeBase}rem auto;
		width: 70%;
	`};

	${(props) => (props.device === Device.TABLET_PORTRAIT || props.device === Device.TABLET_LANDSCAPE) && css`
		margin: ${40 / props.theme.fontSizeBase}rem auto ${27 / props.theme.fontSizeBase}rem auto;
		width: 85%;
	`};

	img {
		cursor: pointer;
	}
`;

const StyledTitle = styled.h1`
	font-weight: 800;
	font-size: ${(props) => 32 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 32 / props.theme.fontSizeBase}rem;
	margin-top: 0;
	margin-bottom: ${(props) => 24 / props.theme.fontSizeBase}rem;
`;

const StyledDescription = styled.div<{ device: Device }>`
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 23 / props.theme.fontSizeBase}rem;
	margin-bottom: ${(props) => 24 / props.theme.fontSizeBase}rem;

	a {
		color: ${(props) => props.theme.black};
		font-weight: 700;
		text-decoration: none;
	}

	${(props) => props.device !== Device.MOBILE && css`
		margin-bottom: ${32 / props.theme.fontSizeBase}rem;
	`};
`;

const StyleSocial = styled.div<{ device: Device }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin: ${(props) => 32 / props.theme.fontSizeBase}rem 0;
	${(props) => props.device !== Device.MOBILE && css`
		margin: 0;
		justify-content: flex-start;
		align-items: flex-start;
	`};

	gap: ${(props) => 16 / props.theme.fontSizeBase}rem;
`;

const StyledButton = styled(LightButton)<{ device: Device, isContact?: boolean }>`
	// margin: ${(props) => 32 / props.theme.fontSizeBase}rem auto;

	${(props) => props.device !== Device.MOBILE && css`
		// margin: 0;
	`};

	${(props) => !!props.isContact && css`
		// margin-top: ${16 / props.theme.fontSizeBase}rem;
	`};
`;

// DESkTOP
const StyledDesktopExperiences = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledContainer = styled.div<{ device: Device }>`
	${(props) => props.device === Device.DESKTOP && css`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 70%;
		margin: 0 auto;
		gap: ${64 / props.theme.fontSizeBase}rem;
	`}

	${(props) => (props.device === Device.TABLET_PORTRAIT || props.device === Device.TABLET_LANDSCAPE) && css`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 85%;
		margin: 0 auto;
		gap: ${32 / props.theme.fontSizeBase}rem;
	`}
`;

const StyledTextContainer = styled.div<{ device: Device }>`
	${(props) => props.device !== Device.MOBILE && css`
		display: flex;
		flex-direction: column;
		// margin-right: ${75 / props.theme.fontSizeBase}rem;
	`}
`;

const IndexPage = ({
	client,
	classicExperiences,
}: Props) => {
	const { t } = useTranslation(['common']);

	const router = useRouter();
	const device = useDevice();
	const { client: clientContext } = useClient();

	// SHARE BUTTON
	const { isSupported, share } = useWebShare();
	const onShare = useCallback(() => {
		if (isSupported) {
			share({
				title: client.attributes.title,
				text:  client.attributes.shareDescription,
				url:   '/',
			});
		}
	}, [client, isSupported, share]);

	// GO TO PARCOURS
	const goToParcours = useCallback((experience: ClassicExperience) => {
		// createEvent({ action: experience.attributes.analyticsEvent ? `choix_parcours_${experience.attributes.analyticsEvent}` : `choix_parcours_${experience.attributes.slug}` });
		router.push(`/parcours/${experience.attributes.slug}`);
	}, [router]);

	// SOCIALS
	const goToFacebook = useCallback(() => {
		if (client.attributes.facebook) {
			// createEvent({
			// 	action:   'facebook',
			// 	category: 'social',
			// });
			gtmEventSocial({
				event_group: 'social',
				event:       'partage',
				client:      clientContext,
			});
			window.open(client.attributes.facebook, '_blank');
		}
	}, [client, clientContext]);

	const goToInstagram = useCallback(() => {
		if (client.attributes.instagram) {
			gtmEventSocial({
				event_group: 'social',
				event:       'partage',
				client:      clientContext,
			});
			window.open(client.attributes.instagram, '_blank');
		}
	}, [client, clientContext]);

	// EMAIL
	const goToEmail = useCallback(() => {
		if (client.attributes.contactEmail) {
			window.open(`mailto:${client.attributes.contactEmail}`);
		}
	}, [client]);

	return (
		<StyledHome device={device}>
			<StyledHeader device={device}>
				<Logo/>
				{ isSupported && device !== Device.MOBILE && <ShareDesktopButton onClick={onShare} /> }
				{ isSupported && device === Device.MOBILE && <IconShare
					width={24}
					height={24}
					color={Theme.textPrimary}
					onClick={onShare}
				/> }
			</StyledHeader>

			<StyledContainer device={device}>
				<StyledTextContainer device={device}>
					<StyledTitle>{ client.attributes.title }</StyledTitle>
					<StyledDescription
						device={device}
						// lines={device === Device.DESKTOP ? 20 : 5}
						// more={t('common:displayMore')}
						// less={t('common:displayLess')}
					>
						{ client.attributes.description }
					</StyledDescription>

					<StyleSocial device={device}>
						{ device !== Device.MOBILE
							&& client.attributes.facebook
							&& client.attributes.facebook.length && <StyledButton
							device={device}
							onClick={goToFacebook}>
							{ t('common:buttonFollowFacebook') }
						</StyledButton> }

						{ device !== Device.MOBILE
							&& client.attributes.instagram
							&& client.attributes.instagram.length && <StyledButton
							device={device}
							hasFbIcon={false}
							hasInstaIcon={true}
							onClick={goToInstagram}>
							{ t('common:buttonFollowInstagram') }
						</StyledButton> }

						{ device !== Device.MOBILE
							&& client.attributes.contactEmail
							&& client.attributes.contactEmail.length
						&& <StyledButton
							isContact={true}
							device={device}
							hasFbIcon={false}
							onClick={goToEmail}>
							{ t('common:buttonContact') }
						</StyledButton> }
					</StyleSocial>

				</StyledTextContainer>

				{ device !== Device.MOBILE && <StyledDesktopExperiences>
					{ classicExperiences?.map((experience, index) => (
						<ClassicExperienceCard onClick={goToParcours} key={index} experience={experience} />
					)) }
				</StyledDesktopExperiences>}

				{ device === Device.MOBILE && <>
					{ classicExperiences?.map((experience, index) => (
						<ClassicExperienceCard onClick={goToParcours} key={index} experience={experience} />
					)) }
				</>}

				<StyleSocial device={device}>
					{ device === Device.MOBILE
						&& client.attributes.facebook
						&& client.attributes.facebook.length
						&& <StyledButton
							device={device}
							onClick={goToFacebook}>
							{ t('common:buttonFollowFacebook') }
						</StyledButton>
					}
					{ device === Device.MOBILE
						&& client.attributes.instagram
						&& client.attributes.instagram.length
						&& <StyledButton
							device={device}
							hasFbIcon={false}
							hasInstaIcon={true}
							onClick={goToInstagram}>
							{ t('common:buttonFollowInstagram') }
						</StyledButton>
					}

					{ device === Device.MOBILE
						&& client.attributes.contactEmail
						&& client.attributes.contactEmail.length
						&& <StyledButton
							device={device}
							hasFbIcon={false}
							onClick={goToEmail}>
							{ t('common:buttonContact') }
						</StyledButton> }
				</StyleSocial>
			</StyledContainer>
		</StyledHome>
	);
};

export default IndexPage;
