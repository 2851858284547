import { FunctionComponent, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { IconInstagram } from '@/components/icon/IconInstagram';
import { IconFacebook } from '@/components/icon/IconFacebook';

interface Props {
	className?: string;
	onClick: () => void;
	hasFbIcon?: boolean;
	hasInstaIcon?: boolean;
}

const StyledLightButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	width: fit-content;
	padding: ${(props) => 10 / props.theme.fontSizeBase}rem ${(props) => 18 / props.theme.fontSizeBase}rem;

	background: ${(props) => props.theme.white};
	border: 2px solid ${(props) => props.theme.btnLight};
	box-sizing: border-box;
	cursor: pointer;
	border-radius: 300px;

	gap: ${(props) => 8 / props.theme.fontSizeBase}rem;
`;

const StyledText = styled.span`
	font-family: Plus Jakarta Sans;
	line-height: ${(props) => 14 / props.theme.fontSizeBase}rem;
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	font-weight: 700;

	color: ${(props) => props.theme.btnLight};
`;

export const LightButton: FunctionComponent<PropsWithChildren<Props>> = ({
	className, children, onClick, hasFbIcon = true, hasInstaIcon = false,
}: PropsWithChildren<Props>) => (
	<StyledLightButton className={className} onClick={onClick}>
		{ hasFbIcon && <>
			<IconFacebook width={24} height={24} />
		</>}
		{ hasInstaIcon && <>
			<IconInstagram width={24} height={24} />
		</>}
		<StyledText>
			{ children }
		</StyledText>
	</StyledLightButton>
);
