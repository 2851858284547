import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconInstagram = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 9.08 8.77"
		xmlSpace="preserve"
		{...props}
	>
		<path d="M6.67.1 3.16.01C2.27-.02 1.4-.01.85.8.32 1.6.2 2.74.09 3.67c-.11.96-.16 1.99.07 2.94.18.75.59 1.47 1.32 1.8.46.21.97.23 1.46.26.59.04 1.19.06 1.78.07.91.02 1.97.12 2.83-.23.89-.36 1.17-1.17 1.25-2.05.11-1.22.24-2.47.27-3.69.03-.89-.12-1.8-.89-2.34-.39-.27-.77.38-.38.65.54.38.54 1.15.52 1.74-.03.9-.13 1.81-.2 2.71-.06.72.04 1.81-.7 2.22-.67.38-1.63.26-2.37.25-.9-.01-1.84.01-2.74-.13-.77-.12-1.2-.62-1.4-1.35-.2-.74-.17-1.54-.12-2.3.06-.86.19-1.76.49-2.58.16-.44.4-.79.89-.87.42-.07.88-.01 1.3 0C4.54.8 5.6.82 6.67.85c.48.01.48-.74 0-.75" />
		<path d="M7.1 2.24a.6.6 0 0 0 .1-.21c.03-.08.04-.16.04-.24.01-.08-.01-.16-.02-.24a.6.6 0 0 0-.08-.22c-.02-.03-.04-.05-.06-.08a.4.4 0 0 0-.12-.08.3.3 0 0 0-.09-.02.26.26 0 0 0-.15.01c-.08.03-.13.05-.19.1s-.1.09-.15.16c-.04.04-.06.1-.08.15a.3.3 0 0 0-.02.08.7.7 0 0 0 .01.35c.02.05.04.11.07.16.06.09.1.12.19.19.04.03.09.04.14.04.05.01.1.01.15-.01.09-.02.18-.09.22-.17a.37.37 0 0 0-.13-.51c.02.01.05.03.07.05a.08.08 0 0 1-.04-.04c.02.02.04.05.05.07a.2.2 0 0 1-.03-.05c.02.03.03.06.04.09-.01-.02-.01-.05-.02-.07 0 .03.01.07.01.1v-.09c0 .03-.01.07-.01.1 0-.03.01-.06.02-.09l-.03.09c.01-.03.02-.05.04-.07-.02.03-.04.05-.06.08a.12.12 0 0 1 .05-.05c-.03.02-.06.04-.08.06.02-.01.03-.02.05-.03-.03.01-.06.02-.09.04.01 0 .01 0 .02-.01-.14-.06-.29-.12-.43-.17.01.01.02.02.02.03-.01-.03-.02-.06-.04-.09.02.04.03.09.04.13 0-.03-.01-.07-.01-.1.01.05.01.09 0 .14 0-.03.01-.07.01-.1-.01.05-.02.09-.04.13.02-.03.03-.06.04-.09-.01.04-.03.07-.06.1-.02.05-.04.09-.04.14-.01.05-.01.1.01.15.02.09.09.18.17.22.09.05.19.07.29.04l.09-.03c.05-.04.1-.08.13-.14m-1.62.66c.31.15.65.42.68.79.03.28-.01.58-.06.84-.06.29-.15.63-.34.83-.09.08-.27.18-.41.25-.2.09-.41.16-.62.2-.78.16-1.62-.18-1.87-.98-.12-.4-.02-.78.19-1.13.11-.18.24-.34.39-.49l.09-.09c.13-.13-.05.02.04-.03.02-.01.03-.03.05-.04l.06-.03c-.03.01-.03.01.01 0 .33-.11.67-.15 1.01-.16.32-.01.6-.03.86.08.19.08.41-.08.46-.26.07-.22-.06-.38-.25-.46-.31-.13-.7-.12-1.03-.11-.41.01-.85.05-1.24.19-.46.16-.78.53-1.04.93-.32.49-.48 1.06-.36 1.63.21 1.09 1.3 1.82 2.38 1.74.54-.04 1.09-.21 1.55-.51.39-.26.6-.65.73-1.09.12-.42.18-.88.15-1.31-.03-.63-.49-1.17-1.05-1.43-.18-.09-.4-.05-.51.13-.1.16-.05.43.13.51" />
	</svg>
));
