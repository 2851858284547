import { NextPage, GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

import ClassicFooter from '@/components/classic/footer/ClassicFooter';
import IndexPage from '@/components/classic/IndexPage';
import ParcoursSlugPage from '@/components/classic/ParcoursSlugPage';
import { useDevice } from '../custom/useDevice';
import { useClassicExperiencesFromClientId } from '../data/ClassicExperience.swr';
import { NextPageProps } from '../typings/NextPageProps';
import { checkClient } from '../utils/serverSide';

export const Home: NextPage<NextPageProps> = ({
	domainEnum, seo, client, host,
}: NextPageProps) => {
	const device = useDevice();
	const router = useRouter();
	const { data: classicExperiences } = useClassicExperiencesFromClientId(client.id, router.locale || 'fr');

	return (
		<>
			<NextSeo { ...seo } />

			{ classicExperiences && classicExperiences.length > 1 && <IndexPage
				classicExperiences={classicExperiences}
				client={client}
			/>}

			{ classicExperiences && classicExperiences.length === 1 && <ParcoursSlugPage
				host={host}
				slug={classicExperiences[0].attributes.slug}
				client={client}
			/>}

			<ClassicFooter domainEnum={domainEnum} device={device} translation={client.attributes.translations} />
		</>
	);
};

export const getServerSideProps: GetServerSideProps = (context) => checkClient(context, ['classic'], ['common']);

export default Home;
