import { FunctionComponent, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { Device, useDevice } from '../../../custom/useDevice';
import { useSreenWidth } from '../../../custom/useScreenWidth';
import { ClassicExperience } from '../../../models/ClassicExperience';

interface Props {
	experience: ClassicExperience;
	onClick?: (experience: ClassicExperience) => void;
}

const StyledExperienceCard = styled.div<{ device: Device, url: string, imageHeight: number, imageWidth: number }>`
	position: relative;
	background: url('${(props) => props.url}');
	width: 100%;
	height: ${(props) => props.imageHeight}px;
	margin-bottom: ${(props) => 16 / props.theme.fontSizeBase}rem;
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;

	${(props) => props.device !== Device.MOBILE && css`
		width: ${props.imageWidth}px;
	`}
`;

const StyledEffect = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.7) 100%);
	opacity: 0.56;
`;

const StyledTitle = styled.span`
	position: absolute;
	font-weight: 900;
	font-size: ${(props) => 18 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 20 / props.theme.fontSizeBase}rem;
	color: ${(props) => props.theme.white};
	left: ${(props) => 16 / props.theme.fontSizeBase}rem;
	bottom: ${(props) => 40 / props.theme.fontSizeBase}rem;
`;

const StyledDuration = styled.div`
	position: absolute;
	right: ${(props) => 16 / props.theme.fontSizeBase}rem;
	bottom: ${(props) => 16 / props.theme.fontSizeBase}rem;
	padding: ${(props) => 4 / props.theme.fontSizeBase}rem ${(props) => 6 / props.theme.fontSizeBase}rem;
	background: ${(props) => props.theme.white};
	border-radius: ${(props) => 2 / props.theme.fontSizeBase}rem;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	font-weight: bold;
	font-size: ${(props) => 12 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
`;

const StyledNumbers = styled.span`
	position: absolute;
	bottom: ${(props) => 16 / props.theme.fontSizeBase}rem;
	left: ${(props) => 16 / props.theme.fontSizeBase}rem;
	font-weight: bold;
	font-size: ${(props) => 12 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
	color: ${(props) => props.theme.white};
`;

export const ClassicExperienceCard: FunctionComponent<Props> = ({ experience, onClick }: Props) => {
	const device = useDevice();
	const screenWidth = useSreenWidth();

	// DESKTOP SCREEN : 1440 x 900 => 453 x 154.33
	const isDeviceMobile = device === Device.MOBILE;
	const imageWidth = isDeviceMobile ? screenWidth - (20 * 2) : (screenWidth * 289) / 834;
	const imageHeight = isDeviceMobile ? (imageWidth * 158) / 335 : (imageWidth * 84) / 289;
	// ratio de MOBILE = 335 x 158 | DESKTOP 453 x 154.33

	const onClickCard = useCallback(() => {
		if (experience.attributes.link && experience.attributes.link.length > 0) {
			window.open(experience.attributes.link, '_blank');
		} else if (onClick) {
			onClick(experience);
		}
	}, [experience, onClick]);

	return (
		<StyledExperienceCard
			device={device}
			imageWidth={imageWidth}
			imageHeight={imageHeight}
			url={experience.attributes.image.data.attributes.url}
			onClick={onClickCard}
		>
			<StyledEffect/>

			<StyledTitle>{ experience.attributes.title }</StyledTitle>

			<StyledNumbers>
				{ experience.attributes.subTitle }
			</StyledNumbers>

			{ experience.attributes.duration > 0 && <>
				<StyledDuration>
					{ experience.attributes.duration } min
				</StyledDuration>
			</> }
		</StyledExperienceCard>
	);
};
