import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'next-i18next';
import Theme from '../../../styles/Theme';
import { IconShare } from '../../icon/IconShare';

interface Props {
	onClick: () => void;
}

const StyledContent = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	border: none;
	box-sizing: border-box;
	cursor: pointer;
	background: ${(props) => props.theme.white};
`;

const StyledText = styled.span`
	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	font-weight: bold;
	font-size: ${(props) => 14 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 24 / props.theme.fontSizeBase}rem;
	margin-left: ${(props) => 11 / props.theme.fontSizeBase}rem;
`;

export const ShareDesktopButton: FunctionComponent<Props> = ({ onClick }: Props) => {
	const { t } = useTranslation(['common']);

	return (
		<StyledContent onClick={onClick}>
			<IconShare width={24} height={24} color={Theme.textPrimary} />
			<StyledText>{ t('common:buttonShare') }</StyledText>
		</StyledContent>
	);
};
