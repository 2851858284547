import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconFacebook = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 5.15 9.26"
		xmlSpace="preserve"
		{...props}
	>
		<style>{'\n    .st16{display:inline;fill:none}\n  '}</style>
		<path
			d="M4.88.13c-.81-.11-1.95-.29-2.7.16-.75.46-.81 1.56-.8 2.34 0 1.01.17 2.01.18 3.02.01 1.08-.08 2.17-.17 3.24h.75c-.06-1.8.32-3.61-.03-5.4-.03-.17-.2-.27-.36-.28-.5-.02-.99.03-1.48.13-.43.09-.3.71.1.74 1.41.09 2.82.03 4.23-.15.47-.06.48-.81 0-.75q-2.1.285-4.23.15c.03.25.07.49.1.74.43-.09.85-.12 1.28-.11l-.36-.27c.33 1.72-.06 3.47 0 5.21.02.49.71.48.75 0 .14-1.73.2-3.43.05-5.16-.07-.77-.21-1.78.14-2.5.19-.4.57-.43.97-.45.46-.02.93 0 1.38.06.47.07.67-.65.2-.72"
			id="visuel_client"
		/>
		<g id="gabarit" className="st0">
			<path
				d="M583.6 78.1v27m0-634.28v-27M-258.29 78.1v27m0-634.28v-27M589.6 72.1h27m-880.89 0h-27M589.6-523.18h27m-880.89 0h-27"
				style={{
					display:          'inline',
					fill:             'none',
					stroke:           '#fff',
					strokeWidth:      1.25,
					strokeMiterlimit: 10,
				}}
			/>
			<path
				d="M583.6 78.1v27m0-634.28v-27M-258.29 78.1v27m0-634.28v-27M589.6 72.1h27m-880.89 0h-27M589.6-523.18h27m-880.89 0h-27"
				style={{
					display:          'inline',
					fill:             'none',
					stroke:           '#000',
					strokeWidth:      0.25,
					strokeMiterlimit: 10,
				}}
			/>
			<path
				style={{
					display: 'inline',
					fill:    'none',
					stroke:  '#1d1d1b',
				}}
				d="M-258.29-523.17H583.6V72.11h-841.89z"
			/>
			<path
				style={{
					display: 'inline',
					fill:    'none',
					stroke:  '#5bc5f2',
				}}
				d="M-272.46-537.35h870.24V86.27h-870.24z"
			/>
			<path className="st16" d="M-272.46-537.35h870.24V86.28h-870.24z" />
			<path
				style={{
					fill:            'none',
					stroke:          '#e6007e',
					strokeWidth:     0.988,
					strokeDasharray: '4.938,4.938',
				}}
				d="M-248.37-513.26h822.05V62.17h-822.05z"
			/>
			<path className="st16" d="M-272.46-537.35h870.24V86.28h-870.24z" />
			<path className="st16" d="M-286.63-551.53h898.58v651.97h-898.58z" />
		</g>
	</svg>
));
